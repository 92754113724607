import { initActCategory, loadActCategory, addActCategory, editActCategory, deleteActCategory, searchActCategory } from "@/api/activiti";
export default {
  name: "category-manage",
  data() {
    return {
      showType: "tree",
      strict: true,
      loading: true,
      loadingEdit: true,
      modalVisible: false,
      selectList: [],
      showParent: false,
      modalTitle: "",
      editTitle: "",
      searchKey: "",
      form: {
        id: "",
        title: "",
        parentId: "",
        parentTitle: "",
        type: "0",
        sortOrder: 0,
        status: 0,
        url: ""
      },
      formAdd: {
        type: null
      },
      formValidate: {
        title: [{
          required: true,
          message: "请输入名称",
          trigger: "blur"
        }],
        type: [{
          required: true,
          message: "请选择类型",
          trigger: "change"
        }],
        sortOrder: [{
          required: true,
          type: "number",
          message: "请输入排序值",
          trigger: "change"
        }]
      },
      submitLoading: false,
      data: [],
      dataEdit: [],
      columns: [{
        type: "selection",
        width: 60,
        align: "center"
      }, {
        type: "index",
        width: 60,
        align: "center"
      }, {
        title: "名称",
        key: "title",
        minWidth: 120,
        sortable: true,
        tree: true,
        render: (h, params) => {
          let icon = "";
          if (params.row.type == "0") {
            icon = "md-folder-open";
          } else if (params.row.type == "1") {
            icon = "ios-bookmark-outline";
          }
          return h("span", [h("span", [h("Icon", {
            props: {
              type: icon,
              size: "16"
            },
            style: {
              "margin-right": "8px"
            }
          }), h("span", params.row.title)])]);
        }
      }, {
        title: "排序",
        key: "sortOrder",
        width: 150,
        sortable: true,
        align: "center",
        sortType: "asc"
      }, {
        title: "创建时间",
        key: "createTime",
        sortable: true,
        width: 200
      }, {
        title: "操作",
        key: "action",
        width: 300,
        align: "center",
        render: (h, params) => {
          return h("div", [h("a", {
            on: {
              click: () => {
                this.tableAdd(params.row);
              }
            }
          }, "添加子类别"), h("Divider", {
            props: {
              type: "vertical"
            }
          }), h("a", {
            on: {
              click: () => {
                this.remove(params.row);
              }
            }
          }, "删除")]);
        }
      }]
    };
  },
  methods: {
    init() {
      this.getParentList();
      this.getParentListEdit();
    },
    renderContent(h, {
      root,
      node,
      data
    }) {
      let icon = "";
      if (data.type == "0") {
        icon = "md-folder-open";
      } else if (data.type == "1") {
        icon = "ios-bookmark-outline";
      }
      return h("span", [h("span", [h("Icon", {
        props: {
          type: icon,
          size: "16"
        },
        style: {
          "margin-right": "8px"
        }
      }), h("span", data.title)])]);
    },
    getParentList() {
      this.loading = true;
      initActCategory().then(res => {
        this.loading = false;
        if (res.success) {
          res.result.forEach(function (e) {
            if (e.isParent) {
              e.loading = false;
              e.children = [];
              e._loading = false;
            }
          });
          this.data = res.result;
        }
      });
    },
    getParentListEdit() {
      this.loadingEdit = true;
      initActCategory().then(res => {
        this.loadingEdit = false;
        if (res.success) {
          res.result.forEach(function (e) {
            if (e.isParent) {
              e.loading = false;
              e.children = [];
            }
          });
          // 头部加入一级
          let first = {
            id: "0",
            title: "一级节点"
          };
          res.result.unshift(first);
          this.dataEdit = res.result;
        }
      });
    },
    loadData(item, callback) {
      loadActCategory(item.id).then(res => {
        if (res.success) {
          res.result.forEach(function (e) {
            if (e.isParent) {
              e.loading = false;
              e.children = [];
              e._loading = false;
            }
          });
          callback(res.result);
        }
      });
    },
    search() {
      if (this.searchKey) {
        this.loading = true;
        searchActCategory({
          title: this.searchKey
        }).then(res => {
          this.loading = false;
          if (res.success) {
            res.result.forEach(function (e) {
              if (e.isParent) {
                e.loading = false;
                e.children = [];
                e._loading = false;
              }
            });
            this.data = res.result;
          }
        });
      } else {
        this.getParentList();
      }
    },
    selectTree(v) {
      if (v.length > 0) {
        this.$refs.form.resetFields();
        // 转换null为""
        for (let attr in v[0]) {
          if (v[0][attr] == null) {
            v[0][attr] = "";
          }
        }
        let str = JSON.stringify(v[0]);
        let data = JSON.parse(str);
        data.type += "";
        this.form = data;
        this.editTitle = data.title;
      } else {
        this.cancelEdit();
      }
    },
    cancelEdit() {
      let data = this.$refs.tree.getSelectedNodes()[0];
      if (data) {
        data.selected = false;
      }
      this.$refs.form.resetFields();
      this.form.id = "";
      delete this.form.id;
      this.editTitle = "";
    },
    selectTreeEdit(v) {
      if (v) {
        // 转换null为""
        for (let attr in v[0]) {
          if (v[0][attr] == null) {
            v[0][attr] = "";
          }
        }
        let str = JSON.stringify(v[0]);
        let data = JSON.parse(str);
        if (this.form.id == data.id) {
          this.$Message.warning("请勿选择自己作为父节点");
          v[0].selected = false;
          return;
        }
        this.form.parentId = data.id;
        this.form.parentTitle = data.title;
      }
    },
    cancelAdd() {
      this.modalVisible = false;
    },
    handleReset() {
      this.$refs.form.resetFields();
      this.form.status = 0;
    },
    submitEdit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (!this.form.id) {
            this.$Message.warning("请先点击选择要修改的节点");
            return;
          }
          this.submitLoading = true;
          editActCategory(this.form).then(res => {
            this.submitLoading = false;
            if (res.success) {
              this.editTitle = this.form.title;
              this.$Message.success("编辑成功");
              this.init();
              this.modalVisible = false;
            }
          });
        }
      });
    },
    submitAdd() {
      this.$refs.formAdd.validate(valid => {
        if (valid) {
          this.submitLoading = true;
          addActCategory(this.formAdd).then(res => {
            this.submitLoading = false;
            if (res.success) {
              this.$Message.success("添加成功");
              this.init();
              this.modalVisible = false;
            }
          });
        }
      });
    },
    add() {
      if (this.form.id == "" || this.form.id == null) {
        this.$Message.warning("请先点击选择一个节点");
        return;
      }
      this.modalTitle = "添加子类别";
      this.showParent = true;
      if (!this.form.children) {
        this.form.children = [];
      }
      this.formAdd = {
        parentId: this.form.id,
        sortOrder: this.form.children.length + 1,
        status: 0,
        icon: "",
        type: "0"
      };
      this.modalVisible = true;
    },
    addRoot() {
      this.modalTitle = "添加一级类别";
      this.showParent = false;
      this.formAdd = {
        parentId: 0,
        sortOrder: this.data.length + 1,
        status: 0,
        icon: "",
        type: "0"
      };
      this.modalVisible = true;
    },
    changeSelect(v) {
      this.selectList = v;
    },
    clearSelectAll() {
      this.$refs.table.selectAll(false);
    },
    tableAdd(v) {
      this.form = v;
      this.add();
      this.editTitle = "";
      let data = this.$refs.tree.getSelectedNodes()[0];
      if (data) {
        data.selected = false;
      }
    },
    showSelect(e) {
      this.selectList = e;
    },
    remove(v) {
      this.selectList = [];
      this.selectList.push(v);
      this.delAll();
    },
    delAll() {
      if (this.selectList.length <= 0) {
        this.$Message.warning("您还未勾选要删除的数据");
        return;
      }
      this.$Modal.confirm({
        title: "确认删除",
        content: "您确认要删除所选的 " + this.selectList.length + " 条数据及其下级所有数据?",
        loading: true,
        onOk: () => {
          let ids = "";
          this.selectList.forEach(function (e) {
            ids += e.id + ",";
          });
          ids = ids.substring(0, ids.length - 1);
          deleteActCategory({
            ids: ids
          }).then(res => {
            this.$Modal.remove();
            if (res.success) {
              this.$Message.success("删除成功");
              this.selectList = [];
              this.cancelEdit();
              this.init();
            }
          });
        }
      });
    }
  },
  mounted() {
    this.init();
  }
};